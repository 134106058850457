.compact-leaderboard {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	&__row {
		display: flex;
		align-items: center;
		font-size: 2em;
		border-collapse: all;
		color: white;
		width: 95%;
		border-bottom: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
	&__nickname {
		font-size: 1.3rem;
		margin-left: 20px;
		color: #333;
		font-weight: 300;
	}
	&__points {
		font-size: 1.3rem;
		margin-left: auto;
		margin-right: 20px;
		color: #333;
	}
	&__rank {
		font-size: 1rem;
		font-weight: 700;
		display: flex;
		height: 60px;
		width: 40px;
		background-color: #0e61cc;
		justify-content: center;
		align-items: center;
	}
	&__row--highlighted {
		width: 100%;
		background-color: #187dff;

		z-index: 10;
		border-top: none;
		border-left: none;
		border-bottom: none;
		border-right: none;
		margin-top: -1px;
		.compact-leaderboard__rank {
			font-size: 1.2rem;
		}
		.compact-leaderboard__points {
			color: white;
			font-size: 1.4rem;
		}
		.compact-leaderboard__nickname {
			color: white;
			font-size: 1.4rem;
		}
	}
}
