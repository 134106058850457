.audio-player {
	background-color: #187dff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	width: 50px;
	height: 50px;
	border-radius: 35px;
	cursor: pointer;

	&__button {
		mask-repeat: no-repeat;
		width: 40px;
		height: 40px;
		background-color: white;
		mask-size: contain;
		mask-image: url('./play.png');
		cursor: pointer;
	}

	&__button:active {
		outline: none;
		background-color: white;
		border-style: none;
	}
}
