.lessons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

@media only screen and (max-width: 48em) {
	.lesson-cell {
		width: 150px;
	}
}
