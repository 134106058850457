.nickname {
  display: flex;
  justify-content: center;
  align-items: top;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 2px 2px 5px #ccc;
  margin-top: 100px;

  &__img {
    height: 200px;
  }

  &__right-container {
    position: relative;
  }

  &__header {
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
  }

  &__input {
    width: 100%;
    border: none;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  &__input:focus {
    outline: none;
  }
  &__error {
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 18px;
    text-align: center;
  }

  &__btn {
    width: 100%;
    margin-top: 40px;
  }
}
