.powerup {
	position: relative;
	padding: 10px 20px;
	width: 60px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&__icon-img {
		width: 40px;
		height: 40px;
		mask-size: contain;
	}

	&__badge {
		position: absolute;
		color: white;
		border-radius: 20px;
		width: 36px;
		height: 36px;
		top: -6px;
		right: -10px;
	}
	&__disabled {
		background-color: #eee !important;
		.powerup__icon-img {
			background-color: #bbb;
		}
	}

	&__dice {
		background-color: #f5e8fa;
		.powerup__badge {
			background-color: #a220e1;
		}
	}

	&__flame {
		background-color: #feeaeb;
		.powerup__badge {
			background-color: #ff3832;
		}
	}

	&__bolt {
		background-color: #edf9ef;
		.powerup__badge {
			background-color: #42d868;
		}
	}

	&__dice-img {
		background-color: #a220e1;
		mask-image: url('dice.png');
	}
	&__flame-img {
		background-color: #ff3832;
		mask-image: url('flame.png');
	}
	&__bolt-img {
		background-color: #42d868;
		mask-image: url('bolt.png');
	}
	&__badge {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__count {
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}
}
