.lesson {
	min-height: 100%;
	width: 100%;

	&__controls {
		display: flex;
		justify-content: center;
	}

	&__powerups {
		display: flex;
		justify-content: center;
	}

	&__answer-input {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}

	&__progress {
		display: flex;
		position: absolute;
		width: 100%;
		top: 10px;
	}
}
