$bronze: #d59468;
$bronze-dark: #82362e;

$silver: #d4d4d4;
$silver-dark: #837a80;

$gold: #f5e1a2;
$gold-dark: #f89320;

.leaderboard {
	background-color: white;
	&__list-header {
		font-size: 2em;
		font-weight: 700;
		margin-top: 10px;
	}
	&__list-item {
		display: flex;
		align-items: center;
		margin: 20px;
		font-size: 2em;
		box-shadow: 1px 1px 4px #bbb;
		background-color: white;
	}
	&__list-item-nickname {
		font-size: 1.6rem;
		margin-left: 20px;
	}
	&__list-item-points {
		font-size: 1.6rem;
		margin-left: auto;
		margin-right: 20px;
		color: #888;
	}
	&__list-item-rank {
		font-size: 1.5rem;
		font-weight: 700;
		display: flex;
		height: 90px;
		width: 70px;
		background-color: #f2f2f2;
		justify-content: center;
		align-items: center;
	}
	&__user-rank {
		border: 1px dashed #ccc;
		border-radius: 10px;
		margin-top: 30px;
		margin-bottom: 40px;
		box-shadow: 0px 0px 3px #ddd;
	}
	&__list-item--highlighted {
		background-color: #187dff;
		color: white;
		.leaderboard__list-item-rank {
			background-color: #0e61cc;
		}
		.leaderboard__list-item-points {
			color: white;
			font-weight: 500;
		}
	}

	&__list-item--gold {
		.leaderboard__list-item-rank {
			color: white;
			background-color: $gold-dark;
		}
	}
	&__list-item--silver {
		.leaderboard__list-item-rank {
			color: white;
			background-color: $silver-dark;
		}
	}
	&__list-item--bronze {
		.leaderboard__list-item-rank {
			background-color: $bronze-dark;
			color: white;
		}
	}
}
