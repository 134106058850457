.check-button {
	cursor: pointer;
	background-color: #42d868;
	outline: none;
	border: none;
	color: white;
	padding: 10px;
	width: 120px;
	height: 70px;
	border-radius: 35px;
	font-weight: 700;
	font-size: 18px;

	&--correct {
		background-color: #187dff;
	}
}
