.header {
  display: flex;
  margin-bottom: 20px;
  > div {
    width: 33.33%;
  }
  &__points {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
  }
  &__nickname {
    color: #333;
    font-size: 1.5em;
    font-weight: 400;
    text-align: left;
  }

  &__coins {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__coins-img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  &__coins-count {
    font-size: 2em;
    font-weight: 600;
  }
  &__leaderboard-icon {
    mask-position: center;
    cursor: pointer;
    margin-top: 10px;
    width: 50px;
    height: 50px;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #f6c443;
    mask-image: url('trophy.png');
  }
}
