.lesson-cell {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  width: 200px;

  &__img {
    width: 150px;
  }

  &__title {
    margin-top: 10px;
    font-weight: 700;
  }

  &__progress {
    width: 150px;
  }
  &--locked .lesson-cell__img {
    opacity: 0.5;
  }
  &--locked .lesson-cell__lock-wrap,
  &--completed .lesson-cell__completed-wrap {
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: white;
    border-radius: 40px;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--locked .lesson-cell__lock,
  &--completed .lesson-cell__completed {
    width: 60px;
  }
}
