.progress {
	height: 10px;
	margin-top: 5px;
	background-color: #eee;
	border-radius: 5px;
	overflow: hidden;

	&__bar {
		height: 10px;
		background-color: #42d868;
		border-radius: 5px;
		transition: width 300ms ease-out;
	}
}
