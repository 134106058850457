.answer-input {
  display: flex;
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 500px;

  .shake {
    animation: shake 1s;
    animation-iteration-count: infinite;
    cursor: pointer;

    .answer-input__field {
      border: 4px solid #42d868;
      background-color: white;
      cursor: pointer;
      padding: 6px;
      padding-left: 8px;
    }
    .answer-input__special-char {
      bottom: 25px;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__field {
    font-family: 'Roboto Mono', monospace;
    margin-right: 10px;
    font-size: 24px;
    background-color: #eee;
    border: none;
    border-radius: 10px;
    padding: 10px;
    padding-left: 12px;
    margin-bottom: 15px;
    box-sizing: border-box;
    transition: box-shadow 300ms ease;
    box-shadow: 0px 0px 0px #ffa02b;
  }
  &__field:focus {
    outline: none;
  }

  &__field--hidden {
    visibility: hidden;
  }
  &__hidden {
    visibility: hidden;
  }
  &__special-char {
    position: absolute;
    bottom: 25px;
    right: 22px;
  }

  // Answer states
  &--incorrect {
    .answer-input__field {
      box-shadow: 0px 3px 0px #ffa02b;
    }
  }

  &--completed {
    .answer-input__field,
    .answer-input__special-char {
      background-color: #edf9ef;
      color: #42d868;
      font-weight: 700;
    }
  }

  &--corrected {
    .answer-input__field {
      font-style: italic;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
