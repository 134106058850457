.landing-page__wrapper {
	min-height: 100%;
	margin-bottom: -80px;
}
.container-fluid {
	max-width: 1440px;
}

.landing-page__bg1 {
	position: absolute;
	right: 0px;
	top: -30px;
	max-width: 60%;
}

.landing-page__hero {
	margin-top: 130px;
}

.landing-page__character {
	margin: auto;
	width: 100%;
	margin-top: 30px;
	margin-left: 30px;
	max-width: 600px;
}

.landing-page__title {
	font-size: 2rem;
	text-align: left;
}
.landing-page__subtitle {
	font-size: 1.25rem;
	text-align: left;
	font-weight: 400;
	margin-top: -10px;
	margin-bottom: 50px;
}

.info {
	margin-left: 50px;
}

.btn {
	background-color: white;
	cursor: pointer;
	font-size: 20px;
	min-width: 125px;
	border-radius: 50px;
	height: 50px;
	padding: 0px 30px;
	border: 2px solid #187dff;
	font-weight: bold;
}

.btn-primary {
	background-color: #187dff;
	color: white;
}

button:focus {
	outline: none;
}

.guest-button {
	background-color: #187dff;
	color: white;
	margin-right: 30px;
	margin-bottom: 10px;
}
.signup-button {
	color: #187dff;
}
.landing-page__links {
	display: flex;
	justify-content: flex-end;
	z-index: 1;
	list-style: none;
	color: white;
	cursor: pointer;
}
.landing-page__links li {
	font-weight: bold;
	margin-right: 50px;
	text-align: right;
	margin-top: 10px;
}
.landing-page__links a {
	text-decoration: none;
	color: white;
}
.landing-page__links li:last-child {
	margin-right: 0;
}
.landing-page__logo {
	z-index: 0;
	font-size: 30px;
	font-weight: 700;
	color: black;
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.landing-page__logo-img {
	width: 50px;
	height: 50px;
	margin-left: 0px;
}
.landing-page__bg2 {
	width: 100%;
	margin-bottom: -5px;
}

.footer {
	height: 30px;
	color: white;
	background-color: #187dff;
	padding-bottom: 10px;
}
.footer a {
	color: white;
	margin-left: 10px;
}

@media only screen and (max-width: 48em) {
	.landing-page__bg1 {
		display: none;
	}
	.landing-page__links {
		display: none;
	}
	.landing-page__logo {
		display: flex;
		justify-content: center;
		font-size: 40px;
	}
	.landing-page__logo-img {
		width: 70px;
		height: 70px;
	}
	.landing-page__hero {
		margin-top: 50px;
	}
	.landing-page__character {
		margin-left: 0px;
	}
	.info {
		margin-left: 0px;
	}
	.landing-page__wrapper {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width: 64em) {
	.landing-page__bg1 {
		position: absolute;
		right: 0px;
		top: 0px;
		width: 65%;
	}
	.landing-page__wrapper {
		margin-bottom: 20px;
	}
}

@media only screen and (min-width: 64em) {
	.landing-page__logo-img {
		margin-left: 35px;
	}
	.about__header {
		margin-left: 50px;
	}
	.landing-page__wrapper {
		margin-bottom: -110px;
	}
}

.signup {
	background-color: #fbfbfb;
	position: absolute;
	width: 100%;
	max-width: 400px;
	outline: none;
	border: 1px solid #ddd;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
	box-shadow: 1px 1px 3px #ddd;
	padding: 10px;
	padding-bottom: 20px;

	h2 {
		margin-top: 10px;
		text-align: center;
	}

	p {
		text-align: center;
		margin-bottom: 20px;
	}

	&__btn {
		margin-bottom: 10px !important;
	}
	&__close {
		cursor: pointer;
		position: absolute;
		right: 5px;
		top: 5px;
		width: 40px;
		height: 40px;
	}
	&__divider {
		margin-top: 20px;
		margin-bottom: 20px;
		position: relative;
		border-top: 1px solid #333;
	}
	&__divider-text {
		position: absolute;
		width: 30px;
		background-color: #fbfbfb;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__guest-button {
		text-align: center;
		a {
			text-decoration: none;
			color: #187dff;
			font-weight: 700;
		}
	}
}
